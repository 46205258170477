import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import AdmissionForm from "../components/AdmissionForm";
import Topbar from "../components/Topbar";


const AdmissionPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | Admission Form">
            <Topbar />
            <NavOne />
            <PageHeader title="Admission Form" />
            <AdmissionForm />
            <Footer />
        </Layout>
    );
};

export default AdmissionPage;
